import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Directive({
    // tslint:disable-next-line:directive-selector
  selector: 'input [ui-input]', standalone: true
})
export class UiInputDirective {
  private showFloatedLabelSubject: Subject<boolean> = new Subject<boolean>();
  private focusSubject: Subject<boolean> = new Subject<boolean>();

  showFloatedLabel$: Observable<boolean> = this.showFloatedLabelSubject.asObservable();
  focus$: Observable<boolean> = this.focusSubject.asObservable();

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    this.renderer.addClass(this.elementRef.nativeElement, 'ui-input');
  }

  @HostListener('change', ['$event.target'])
  onInputChange(input: HTMLInputElement) {
    this.showFloatedLabelSubject.next(input?.value?.length > 0);
  }

  @HostListener('focus', ['$event.target'])
  onFocus(_input: HTMLInputElement) {
    this.showFloatedLabelSubject.next(true);
    this.focusSubject.next(true);
  }

  @HostListener('blur', ['$event.target'])
  onBlur(input: HTMLInputElement) {
    this.showFloatedLabelSubject.next(input?.value?.length > 0);
    this.focusSubject.next(false);
  }
}
