import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {catchError, map} from 'rxjs/operators';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import {genRouterLink} from '@util/route';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const skipChecking = request.headers.get('skip');
        const nonRedirectChecking = request.headers.get('nonRedirect');
        if (!request.headers.get('Content-Type')) {
            const requestParams = request.params;
            let requestHeader = request.headers;
            if (skipChecking != null) {
                requestHeader = requestHeader.delete('skip');
            }
            if (nonRedirectChecking != null) {
                requestHeader = requestHeader.delete('nonRedirect');
            }
            requestHeader = requestHeader.append('Access-Control-Allow-Origin', '*');
            requestHeader = requestHeader.append('Access-Control-Allow-Credentials', 'true');
            requestHeader = requestHeader.append('Accept', 'application/json; charset=utf-8');

            request = request.clone({
                headers: requestHeader,
                withCredentials: true,
                params: requestParams
            });
        }

        return next.handle(request).pipe(
            map((evt: HttpEvent<any>) => {
                return evt;
            }),
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status !== 200 && skipChecking !== 'true') {
                        if (err.status === 403) {
                            this.errorNavHandle('Please try refresh your page.', 'warning' );
                        }
                        else if (this.skipRedirectForSpecialCode(err.status)) {
                            // do nothing
                        }
                        else {
                            this.errorNavHandle('Some error happen in backend', 'error' , nonRedirectChecking);
                        }
                    } else {
                        console.log('API call with skip checking [' + err.status + '][' + request.url + ']');
                    }
                }
                return throwError(err);
            })
        );
    }

    skipRedirectForSpecialCode(statusCode: number) {
        return statusCode === 401 || statusCode === 1100 || statusCode === 1200 || statusCode === 1300;
    }

    errorNavHandle(alertMsg?: string, alertType?: SweetAlertIcon, nonRedirectChecking?: string) {
        if (alertMsg == null) {
            alertMsg = 'Some error happen';
        }
        if (alertType == null) {
            alertType = 'warning';
        }
        Swal.fire('', alertMsg , alertType).then(() => {
            if (nonRedirectChecking !== 'true') {
                this.router.navigate([genRouterLink('/')]).then();
            }
        });
    }
}
