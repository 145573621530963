import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzModalRef} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-top-pop-message-modal',
  standalone: true,
  imports: [CommonModule, NzIconModule],
  templateUrl: './top-pop-message-modal.component.html',
  styleUrls: ['./top-pop-message-modal.component.less']
})
export class TopPopMessageModalComponent implements OnInit {

  @Input() type: string;
  @Input() titleText: string;
  @Input() bodyText: string;

  constructor(private modal: NzModalRef) { }

  ngOnInit(): void {
  }

}
