import {Component, Input, OnInit} from '@angular/core';
import {NgTemplateOutlet} from '@angular/common';

@Component({
  selector: 'app-basic-header',
  templateUrl: './basic-header.component.html',
  styles: [
  ]
})
export class BasicHeaderComponent implements OnInit {

    @Input() title: NgTemplateOutlet;
    @Input() position: 'default' | 'center' = 'default';

  constructor() { }

  ngOnInit(): void {
  }

}
