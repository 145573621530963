import {Component, ElementRef, forwardRef, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {FloatingLabelComponent} from '@modules/shared-lib/common/component-revamp/ui-input/floating-label/floating-label.component';
import {SharedCommonModule} from '@modules/shared-lib/common/common.module';
import {NumericDirective} from '@modules/shared-lib/common/component-revamp/ui-input/floating-label/directive/numeric.directive';
import {UiInputDirective} from '@modules/shared-lib/common/component-revamp/ui-input/floating-label/directive/ui-input.directive';

@Component({
    selector: 'app-ui-input',
    standalone: true,
    templateUrl: './ui-input.component.html',
    imports: [CommonModule, FloatingLabelComponent, FormsModule, SharedCommonModule, NumericDirective, UiInputDirective],
    styleUrls: ['./ui-input.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [
      {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => UiInputComponent),
          multi: true
      }
    ]
})
export class UiInputComponent implements OnInit {

  @ViewChild('input', { static: false }) inputRef: ElementRef;

  // API
  @Input() nzModel!: any;
  @Input() nzPlaceHolder = '';
  @Input() nzSize: any = 'default';
  @Input() nzData!: any;
  @Input() nzCtrl!: string | AbstractControl;
  @Input() nzForm!: FormGroup;
  @Input() nzWrapClassName = '';
  @Input() nzClass!: string;
  @Input() nzType = 'text';
  @Input() nzPrefix: string | TemplateRef<void> = '';
  @Input() nzSuffix: string | TemplateRef<void> = '';
  @Input() nzDisabled = false;
  @Input() nzDisabledBackground = false;
  @Input() nzNumericType: 'number' | 'decimal' | 'currency' | undefined;
  @Input() nzDecimal: number;
  @Input() nzDisplayWithDp = false;
  @Input() autocomplete = 'on';
  @Input() nzMaxLength: number | string;
  @Input() nzWithMlWrapper = true;
  @Input() nzAccountOpeningDisabled = false;
  @Input() nzReadonly = false;
    _uppercase = false;

  // Variables
  hasPrefixAndSuffix = false;
  inputModel: any | undefined;
  public disabled: boolean;

  constructor() { }

  ngOnInit(): void {
    if (this.nzPrefix !== '' || this.nzSuffix !== '') {
      this.hasPrefixAndSuffix = true;
    }
  }

  // Auto binding
  onChange: any = () => { };
  onTouch: any = () => { };
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  get uppercase(): boolean {
      return this._uppercase;
  }

  // Define what should happen in this component, if something changes outside
  writeValue(input: any) {
    this.inputModel = input;
  }

  setDisabledState(isDisabled: boolean): void {
    this.nzDisabled = isDisabled;
  }

  onInputChange($event: string): void {
    if (this.uppercase && $event) {
        this.inputModel = $event.toUpperCase();
    } else {
        this.inputModel = $event;
    }
    this.onChange(this.inputModel);
  }

  highlightText() {
    if (this.inputRef && this.inputRef.nativeElement) {
      const inputElement: HTMLInputElement = this.inputRef.nativeElement;
      inputElement.focus();
      inputElement.select();
    }
  }
}
