import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {registerLocaleData} from '@angular/common';
import en from '@angular/common/locales/en';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NzOverlayModule} from 'ng-zorro-antd/core/overlay';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';

import {IconsProviderModule} from './icons-provider.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NzMessageService} from 'ng-zorro-antd/message';
import {PopMessageBoxModule} from '@modules/shared-lib/common/component/pop-message-box/pop-message-box.module';
import {PopMessageModalModule} from '@modules/shared-lib/common/component/pop-message-modal/pop-message-modal.module';
import {BreadcrumbModule} from 'xng-breadcrumb';
import {AuthModule} from '@modules/auth/auth.module';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {CoreModule} from '@core/core.module';
import {LocalStorageService} from '@core/service/local-storage.service';
import {
    ShareModalModule
} from "@modules/shared-lib/common/component-revamp/article/share-article-modal/share-modal.module";
import {NgProgressModule} from 'ngx-progressbar';
import {NgProgressRouterModule} from 'ngx-progressbar/router';

registerLocaleData(en);

const DECLARATION = [
    AppComponent
];

const IMPORTS = [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    BreadcrumbModule,
    AppRoutingModule,

    NzOverlayModule,
    NzDrawerModule,
    PopMessageBoxModule,
    PopMessageModalModule,
    AuthModule,
    CoreModule.forRoot(),
    ShareModalModule,
    NgProgressModule.withConfig({spinner: false, color: '#F26E2E'}),
    NgProgressRouterModule,
];

@NgModule({
    declarations: [...DECLARATION],
    imports: [...IMPORTS],
    providers: [
        NzMessageService,
        NzNotificationService,
        LocalStorageService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
