<ng-container *nzModalTitle>
    <h4 class="mb-0">Forget Password</h4>
</ng-container>


<div class="bg-black-3 p-3 rounded-lg shadow-sm mb-4">
    <h4 class="text-base mb-2">Note(s)</h4>
    <p class="text-xs text-black mb-0">1. Please insert your username</p>
    <p class="text-xs text-black mb-0">2. Please insert your NRIC</p>
</div>

<div class="text-center mb-4">
    <img src="assets/images/account/pc-question.svg" alt="iFAST FM">
</div>

<ng-container>
    <ng-container *ngTemplateOutlet="verifyUserFormTpl"></ng-container>
</ng-container>


<ng-container *nzModalFooter>
    <div class="text-center">
        <button
                [disabled]="form.invalid"
                nz-button
                [nzLoading]="submitLoading"
                nzType="primary"
                (click)="verifyUsernameAndNric()"
        >Submit
        </button>
    </div>
</ng-container>


<ng-template #verifyUserFormTpl>
    <form nz-form [formGroup]="form" class="form-container">
        <div class="mb-2">
            <nz-form-item class="mb-0">
                <nz-form-control>
                    <app-ui-input nzType="email" formControlName="username" nzPlaceHolder="Username" (input)="onChangeUsername($event?.target?.value)"></app-ui-input>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div>
            <nz-form-item class="mb-0">
                <nz-form-control>
                    <app-ui-input-wrapper>
                        <nz-input-group>
                            <app-ui-input nzType="text" formControlName="nric" nzPlaceHolder="NRIC"></app-ui-input>
                        </nz-input-group>
                    </app-ui-input-wrapper>
                </nz-form-control>
            </nz-form-item>
        </div>
    </form>
</ng-template>
