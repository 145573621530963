import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NzModalRef} from 'ng-zorro-antd/modal';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzWaveModule} from 'ng-zorro-antd/core/wave';
import {NzInputModule} from "ng-zorro-antd/input";
import {FormsModule} from "@angular/forms";
import {UiInputComponent} from "@modules/shared-lib/common/component-revamp/ui-input/ui-input.component";

@Component({
    selector: 'app-confirmation-modal',
    standalone: true,
    imports: [CommonModule, NzButtonModule, NzIconModule, NzWaveModule, NzInputModule, FormsModule, UiInputComponent],
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.less']
})
export class ConfirmationModalComponent implements OnInit {

    constructor(private modal: NzModalRef) {
    }

    @Input() titleText: string;
    @Input() bodyText: string;
    @Input() bodyInputType: 'text' | 'textarea' | null;
    @Input() bodyInputClass: string;
    @Input() bodyInputPlaceholder: string;
    @Input() confirmBtn = false;
    @Input() confirmBtnHtml: string;
    @Input() cancelBtn = false;
    @Input() cancelBtnHtml: string;

    input: any;

    @Output() event: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit(): void {
        if (!this.confirmBtn && !this.cancelBtn) {
            this.confirmBtn = true;
        }
    }

    get disableConfirm(): boolean {
        if (this.bodyInputType) {
            return !this.input;
        }
        return false;
    }

    emitData(data: any) {
        // console.log('this is within alert box component');
        this.modal.destroy(data);
    }
}
