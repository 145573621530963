import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {copyToClipboard} from '../../others/copy-clipboard';
import {Router} from '@angular/router';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Component({
    selector: 'app-social-share',
    templateUrl: './social-share.component.html',
    styleUrls: ['./social-share.component.scss'],
    providers: [NzNotificationService]
})
export class SocialShareComponent implements OnInit {
    @Input() url: string;
    @Input() isVisible: boolean;
    @Output() closeModal = new EventEmitter<any>();

    constructedFacebookLink = '';
    constructedTwitterLink = '';
    constructedEmailLink = '';
    constructedTelegramLink = '';
    constructedWhatsappLink = '';

    readonly DOUBLE_SLASH: string = '//';

    constructor(private router: Router,
                private nzNotificationService: NzNotificationService) { }

    ngOnInit(): void {
        this.isVisible = false;

        if (!this.url || this.url.length === 0) {
            this.url = window.location.hostname + this.router.url;
        }

        this.generateFacebookLink();
        this.generateTwitterLink();
        this.generateEmailLink();
        this.generateTelegramLink();

        this.generateWhatsappLink();
    }

    handleClose() {
        this.isVisible = false;
        this.closeModal.emit();
    }

    showModal() {
        this.isVisible = true;
    }

    private generateFacebookLink() {
        const FB_URL = 'https://www.facebook.com/sharer/sharer.php?u=';
        this.constructedFacebookLink = FB_URL + this.getFullLink(this.url);
    }

    private generateTwitterLink() {
        const TWITTER_URL = 'https://twitter.com/share?url=';
        this.constructedTwitterLink = TWITTER_URL + this.getFullLink(this.url);
    }

    private generateEmailLink() {
        this.constructedEmailLink = 'mailto:?subject=iFAST-DWS Fund Management&body=' + this.getFullLink(this.url);
        console.log(this.constructedEmailLink);
    }

    private generateTelegramLink() {
        const TELE_URL = 'https://telegram.me/share/url?url=';
        this.constructedTelegramLink = TELE_URL + encodeURIComponent(this.getFullLink(this.url));
    }

    private getFullLink(url: string): string {
        return location.protocol + this.DOUBLE_SLASH + url;
    }

    // private getContextPath() {
    //     return window.location.pathname.substring(0, window.location.pathname.indexOf("/", 2));
    // }

    shareLink() {
        copyToClipboard(this.getFullLink(this.url));
        this.nzNotificationService.info('Success!', 'Link has been copied to clipboard.');
    }

    get getClipboardLink(): string {
        return this.getFullLink(this.url);
    }

    private generateWhatsappLink() {
        const WHATSAPP_URL = 'https://web.whatsapp.com/send?text=';
        this.constructedWhatsappLink = WHATSAPP_URL + encodeURIComponent(this.url);
    }

}
