import {FmClientModel} from '@model/account/fm-client.model';
import {AuthStateModel} from '@core/store';

export class LoginSuccess {
    static readonly type: string = '[Auth] Login Success';

    constructor(public auth: AuthStateModel) {

    }
}


export class SetFmClient {
    static readonly type: string = '[Auth] Set Fm Client Detail';

    constructor(public fmClientModel: FmClientModel) {

    }
}

export class SetTwoFA {
    static readonly type: string = '[Auth] Set Two FA Flag';

    constructor(public flag: string) {
    }
}

export class SetFirstTimeLogin {
    static readonly type: string = '[Auth] Set First Time Login Flag';

    constructor(public flag: string) {
    }
}

export class Logout {
    static readonly type: string = '[Auth] Logout';
}
