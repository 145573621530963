import {EventEmitter, Injectable} from '@angular/core';
import {AuthRestService} from '@api/service/api/auth.rest.service';
import {Observable} from 'rxjs';
import {PresessionModel, RSAEngineModel} from '@model/account/fm-client.model';
import {map} from 'rxjs/operators';
import {IResponse} from '@modules/shared-lib/common/model/i-response';
import {AppConstant} from '@constant/app.constant';
import {AuthStateModel, GlobalState} from '@core/store';
import {Select, Store} from '@ngxs/store';
import {TwoFaRegistrationComponent} from '@modules/auth/component/two-fa-registration/two-fa-registration.component';
import {Router} from '@angular/router';
import {NzModalService} from 'ng-zorro-antd/modal';
import {ResetPasswordModalComponent} from '@modules/auth/component/reset-password-modal/reset-password-modal.component';
import {RouteConstant} from '@constant/route.constant';
import {LoginSuccess, Logout} from '@store/action/auth.action';
import {AccountRestService} from "@api/service/api/account.rest.service";
import {deepCopy} from "@util/sort";

declare var RSAEngine: any;

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
    isMobile: boolean = false;
    twoFaBlock = false;

    loginSuccessRes: AuthStateModel;
    firstTimeLoginFlowEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private authRestService: AuthRestService,
        private router: Router,
        private nzModalService: NzModalService,
        private accountRestService: AccountRestService,
        private store: Store,
    ) {
        this.isMobile$.subscribe((isMobile: boolean) => {
            this.isMobile = isMobile;
        })

        this.firstTimeLoginFlowEvent.subscribe(res => {
            this.store.dispatch(new LoginSuccess(this.loginSuccessRes));
        })
    }

    getPresession(type?: string): Observable<PresessionModel> {
        return this.authRestService.getPresession(type).pipe(
            map((res: IResponse<PresessionModel>) => {
                if (res && res.data && res.status === AppConstant.RESPONSE_SUCCESS) {
                    return res.data;
                }
            })
        );
    }

    getNewRsaEngineWithKeys(e2eePkey: string, e2eeSession: string, e2eeRandom: string): RSAEngineModel {
        const rsaEngine: RSAEngineModel = new RSAEngine();
        rsaEngine.init(e2eePkey, e2eeSession, e2eeRandom);

        return rsaEngine;
    }

    login(username: string, password: string, randomNumber: string, sid: string): Observable<any> {
        return this.authRestService.login(username, password, randomNumber, sid);
    }

    loginSuccessHandler(loginRes: any) {

        const res: AuthStateModel = loginRes.body;
        this.loginSuccessRes = deepCopy(res);
        const isTwoFaRegister = res.fmClientModel?.isTwoFaRegister;
        const isFirstTimeLogin = res.fmClientModel?.isFirstTimeLogin;
        const clientEmail = res.fmClientModel?.email;
        const username = res.fmClientModel?.username;

        console.log('two fa', isTwoFaRegister);
        console.log('first time', isFirstTimeLogin);
        if (isTwoFaRegister === AppConstant.NO_FLAG) {
            this.openTwoFaModal(username, clientEmail);
        } else if (isFirstTimeLogin === AppConstant.YES_FLAG) {
            this.openResetPasswordModal(username);
        } else {
            this.store.dispatch(new LoginSuccess(loginRes.body));
            this.router.navigate([RouteConstant.DASHBOARD]);
        }
    }

    openTwoFaModal(username: string, email: string) {
        const modal = this.nzModalService.create({
            nzWrapClassName: this.isMobile? 'basic-ui-modal' : 'basic-ui-modal w850-screen',
            nzCentered: true,
            nzClosable: false,
            nzMaskClosable: false,
            nzContent: TwoFaRegistrationComponent,
            nzComponentParams: {
                username: username,
                clientEmail: email,
            }
        });
    }

    openResetPasswordModal(username: string) {
        this.nzModalService.create({
            nzWrapClassName: this.isMobile? 'basic-ui-modal grey-modal' : 'basic-ui-modal grey-modal w650-screen',
            nzCentered: true,
            nzClosable: true,
            nzMaskClosable: false,
            nzContent: ResetPasswordModalComponent,
            nzComponentParams: {
                title: 'Reset Password',
                type: 'resetPass',
                username: username
            }
        });
    }

    logout() {
        this.authRestService.logout().subscribe(() => {
            console.log('logout');
        });
        this.dispatchLogout();
    }

    dispatchLogout() {
        this.store.dispatch(new Logout());
        this.router.navigate([RouteConstant.LOGIN]);
    }
}
