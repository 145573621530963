export const copyToClipboard = (value: string) => {
  const input = document.createElement('textarea');
  input.classList.add('hidden-copy');
  input.style.position = 'fixed';
  input.innerHTML = value;

  document.body.appendChild(input);
  input.focus();
  input.select();

  document.execCommand('copy');
  document.body.removeChild(input);
};
