import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {RouteConstant} from '@constant/route.constant';
import {genRouterLink} from '@util/route';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {OurFundsService} from '@shared/service/api/our-funds.service';
import {NavigationExtras, Router} from '@angular/router';
import {SubHandlingService} from '@modules/shared-lib/common/service/subscription-handling.service';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    startWith,
    switchMap,
    takeUntil,
    tap
} from 'rxjs/operators';
import {catchErrResponse, mapArrayResponse} from '@modules/shared-lib/common/others/obs-helper';
import {IOurFundsTableModel} from '@model/i-our-funds-table.model';
import {interval, Observable, of, Subject, Subscription} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {AuthState, GlobalState} from '@core/store';
import {AuthService} from '@service/auth/auth.service';
import {Logout} from '@store/action/auth.action';
import {AuthRestService} from '@api/service/api/auth.rest.service';
import {mobilePostNavData, postNavData} from '@core/component/header/post-nav-data';
import {RightsService} from '@service/auth/rights.service';
import {PageConstant} from '@constant/page.constant';
import {INav} from "@core/interface/i-nav";
import {PopMessageService} from "@service/pop-message.service";
import {AccountRestService} from "@api/service/api/account.rest.service";
import {AppConstant} from "@constant/app.constant";
import {HeaderService} from "@core/component/header/header.service";
import {RightsConstant} from "@constant/rights.constant";
import {RecommendationInfoModel} from "@model/portfolio-management/i-portfolio-recommendation.model";

@Component({
    selector: 'app-main-layout-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [SubHandlingService, UntypedFormBuilder, Store],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Select(AuthState.getClientName) clientName: Observable<string>;

    constructor(private fb: UntypedFormBuilder,
                private ourFundsService: OurFundsService,
                private router: Router,
                private store: Store,
                private authRestService: AuthRestService,
                private accountRestService: AccountRestService,
                private authService: AuthService,
                private subHandler: SubHandlingService,
                private rightsService: RightsService,
                private popMessageService: PopMessageService,
                private headerService: HeaderService,
    ) {
    }

    get searchCtrl(): AbstractControl {
        return this.form.get('search');
    }
    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
    @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;
    drawerVisible = false;
    searchDrawerVisible = false;
    @Input() isPostLoginMenu = false;

    logoUrl = 'assets/images/ifm-logo@2x.png';
    readonly RouteConstant = RouteConstant;
    readonly genRouterLink = genRouterLink;
    postNavData = postNavData;
    mobilePostNavData = mobilePostNavData;
    originalPostNavData = postNavData;

    searchQuery!: string;
    loading = false;
    resultList: IOurFundsTableModel[] = [];
    currentPage = 0;

    aboutUsNav = false;
    ourFundsNav = false;
    contactUsNav = false;
    showChildrenRoute = false;

    form: UntypedFormGroup = this.fb.group({
        search: ['']
    });
    isAuthenticated = false;
    authenticationSubscription: Subscription;
    pendingOrderApiSubscription: Subscription;
    pendingOrders: number = 0;
    pendingOrderItems: number = 0;

    isSearchBarShow = false;
    searchLength$: Observable<number>;
    searchBarPreHover: number = undefined;
    navigationStack: any[] = [];

    private unsubscribe$ = new Subject<void>();

    ngOnDestroy(): void {
        this.pendingOrderApiSubscription?.unsubscribe()
        this.authenticationSubscription?.unsubscribe();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {
        this.form.reset();
        this.isSearchBarShow = false;

        this.subHandler.subscribe(this.searchCtrl.valueChanges.pipe(
            filter((res?: string) => res?.length >= 2),
            debounceTime(400),
            distinctUntilChanged(),
            tap((query: string) => {
                this.searchQuery = query;
                this.currentPage = 0;
                this.search(this.searchQuery, false);
                console.log(query);
            })
        ));

        this.searchLength$ = this.form.get('search').valueChanges.pipe(startWith(''), map((val: any) => {
            return !val || false || false ? 0 : val.length;
        }));

        this.authenticationSubscription = this.isAuthenticated$.subscribe((res) => {
            this.isAuthenticated = res;
            this.onAuthChange();
        });
        this.onAuthChange();
    }

    onAuthChange() {
        console.log('onAuthChange', this.isAuthenticated);
        if (this.isAuthenticated) {
            if (!this.isPostLoginMenu) {
                // check for status active for pre login, if post login through auth guard to check
                this.checkActive();
            } else {
                // long polling api every 3s
                if ((!this.pendingOrderApiSubscription || this.pendingOrderApiSubscription.closed) && this.rightsService.checkRights([RightsConstant.PENDING_ORDER_VIEW])) {
                    this.pendingOrderApiSubscription = interval(3000).pipe(
                        startWith(0),
                        switchMap(() => this.accountRestService.getPendingOrders()),
                        takeUntil(this.unsubscribe$)
                    ).subscribe(
                        res => {
                            if (res && res.status === AppConstant.RESPONSE_SUCCESS) {
                                this.processNavData(res.data);
                            } else {
                                this.processNavData({});
                            }
                        },
                        error => {
                            this.processNavData({});
                        }
                    );
                }
            }
        } else {
            if (this.pendingOrderApiSubscription && !this.pendingOrderApiSubscription.closed) {
                this.pendingOrderApiSubscription.unsubscribe();
            }
        }
    }

    searchMore() {
        this.currentPage += 1;
        this.search(this.searchQuery, true);
    }

    navigate(item: any) {
        console.log('navigate >>> ', item);
        // this.blockAutoCompleteEvent();

        if (!item) {
            return;
        }

        let commands: Array<any>;
        const extras: NavigationExtras = {
            replaceUrl: true,
            skipLocationChange: false
        };

        commands = [RouteConstant.OUR_FUNDS, RouteConstant.OVERVIEW];
        extras.queryParams = {
            id: item.id, curr: item.currency
        };

        this.closeSearchDrawer();

        // const urlTree: UrlTree = this.router.createUrlTree([...commands],
        //     {...extras});
        //
        // console.log(urlTree);
        // this.form.get('search').reset();
        // this.isSearchBarShow = false;
        // window.open(this.urlSerializer.serialize(urlTree), '_blank');
        this.router.navigate(commands, extras).then(() => {
            this.form.get('search').reset();
            this.isSearchBarShow = true;
            window.location.reload();
            window.scrollTo({
                top: 0,
                left: 0,
            });
        });
    }

    navigateByKey() {
        if (this.searchBarPreHover !== undefined && this.resultList.length > 0) {
            const item = this.resultList[this.searchBarPreHover];
            this.navigate(item);
        }
    }

    showSearchBar() {
        this.form.get('search').reset();
        this.isSearchBarShow = !this.isSearchBarShow;
    }

    hideSearchBar() {
        this.form.get('search').reset();
        this.isSearchBarShow = false;
    }

    private search(keyword?: string, concat?: boolean) {
        this.loading = true;

        this.subHandler.subscribe(
            this.ourFundsService.findProductsByKeyword(keyword, this.currentPage, 100, true).pipe(
                catchErrResponse(),
                mapArrayResponse(),
                tap((result: any[]) => {
                    this.resultList = concat ? [...this.resultList, ...result] : [...result];
                    this.searchBarPreHover = 0;
                    this.loading = false;
                })
            )
        );
    }

    closeDrawer() {
        this.drawerVisible = false;
        this.goBackToOriginalList();
    }

    closeSearchDrawer() {
        this.searchDrawerVisible = false;
    }

    showDrawer() {
        this.drawerVisible = true;
    }

    showSearchDrawer() {
        this.form.get('search').reset();
        this.searchDrawerVisible = true;
    }

    redirectToDestination(url?: string) {
        this.contactUsNav = false;
        this.aboutUsNav = false;
        this.ourFundsNav = false;

        this.drawerVisible = false;
        this.router.navigate([genRouterLink(url)]).then(() => {
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                });
            }, 100);
        });
    }

    keyDownPress() {
        if (this.searchBarPreHover < this.resultList.length - 1) {
            ++this.searchBarPreHover;
        }
        console.log(this.searchBarPreHover);
    }
    keyUpPress() {
        if (this.searchBarPreHover > 0) {
            --this.searchBarPreHover;
        }
        console.log(this.searchBarPreHover);
    }

    checkForLogout(title: string) {
        if (title === PageConstant.LOGOUT) {
            this.logout();
        }
    }

    logout() {
        this.router.navigate([RouteConstant.LOGIN]).then(
            () => {
                this.authRestService.logout().subscribe();
                this.popMessageService.openTopPopMessageModalPreLoginModal({
                    type: 'success',
                    titleText: 'Success!',
                    bodyText: 'Logout Successful.'
                }).then();
                this.store.dispatch(new Logout());
            }
        );
    }

    hasAccess(access: string[]) {
        return this.rightsService.checkRights(access);
    }

    showChildren(item: any): void {
        // If the item has children
        if (item.children && item.children.length > 0) {
            // Push the current postNavData to the navigation stack
            this.navigationStack.push([...this.postNavData]);

            // Replace postNavData with the children of the clicked item
            this.postNavData = item.children;
        }
    }

    goBack(): void {
        // Pop the last item from the navigation stack
        const previousNavData = this.navigationStack.pop();
        if (previousNavData) {
            this.postNavData = previousNavData;
        }
    }
    goBackToOriginalList(): void {
        // Clear the navigation stack
        this.navigationStack = [];

        // Restore the original postNavData
        this.postNavData = this.originalPostNavData;
    }

    isCurrentRoute(url?: string) {
        return this.router.url?.includes(url);
    }

    checkActive() {
        this.subHandler.once(
            this.authRestService.checkToken().pipe(
                tap((res: any) => {
                    if (res.data.active) {
                        return true;
                    }
                    this.authService.logout();
                    return false;
                }),
                catchError((error) => {
                    console.log('error', error);
                    this.authService.dispatchLogout();
                    return of(false);
                })
            )
        );
    }

    processNavData(infoModel: RecommendationInfoModel) {
        // const pendingOrder = infoModel.pendingOrders || 0;
        // const pendingOrderItems = infoModel.pendingOrderItems || 0;
        // if (this.pendingOrders === pendingOrder && this.pendingOrderItems === pendingOrderItems) {
        //     return;
        // }
        // this.pendingOrders = pendingOrder;
        // this.pendingOrderItems = pendingOrderItems;
        // this.headerService.pendingOrderUpdateEvent.emit(pendingOrder);
        // // find pending order recursively
        // const pendingOrdersNavItem = this.findNavItemByTitle(postNavData, PageConstant.MY_APPROVALS);
        // const mobilePendingOrderNav = this.findNavItemByTitle(mobilePostNavData, PageConstant.PENDING_ORDERS);
        // const pendingApprovalOrdersNavItem = this.findNavItemByTitle(postNavData, PageConstant.ORDER_APPROVAL);
        // const mobilePendingApprovalOrdersNav = this.findNavItemByTitle(mobilePostNavData, PageConstant.ORDER_APPROVAL);
        // if (pendingApprovalOrdersNavItem) {
        //     if (this.hasAccess(pendingApprovalOrdersNavItem.right)) {
        //         this.setPendingOrders(pendingApprovalOrdersNavItem, pendingOrder);
        //         this.setPendingOrders(mobilePendingApprovalOrdersNav, pendingOrder);
        //         this.setPendingOrders(pendingOrdersNavItem, 0);
        //         this.setPendingOrders(mobilePendingOrderNav, 0);
        //     }
        //     else {
        //         this.setPendingOrders(pendingOrdersNavItem, pendingOrder);
        //         this.setPendingOrders(mobilePendingOrderNav, pendingOrder);
        //         this.setPendingOrders(pendingApprovalOrdersNavItem, 0);
        //         this.setPendingOrders(mobilePendingApprovalOrdersNav, 0);
        //     }
        // }
        // else {
        //     this.setPendingOrders(pendingOrdersNavItem, pendingOrder);
        //     this.setPendingOrders(mobilePendingOrderNav, pendingOrder);
        // }
    }

    setPendingOrders(navItems: INav, pendingOrder: number) {
        if (navItems) {
            navItems.pendingNumber = pendingOrder;
            this.originalPostNavData = this.postNavData.slice();
        }
    }

    findNavItemByTitle(navData: INav[], title: string): INav | null {
        for (const item of navData) {
            if (item.title === title) {
                return item;
            }
            if (item.children) {
                const foundItem = this.findNavItemByTitle(item.children, title);
                if (foundItem) {
                    return foundItem;
                }
            }
        }
        return null;
    }
}
