// Commonly used NG-ZORRO UI component modules will be imported directly here for easier development

import {NzBackTopModule} from 'ng-zorro-antd/back-top';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzMenuModule} from 'ng-zorro-antd/menu';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzAnchorModule} from 'ng-zorro-antd/anchor';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzCarouselModule} from 'ng-zorro-antd/carousel';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import {NzOverlayModule} from 'ng-zorro-antd/core/overlay';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import {NzAffixModule} from 'ng-zorro-antd/affix';
import {NzCollapseModule} from 'ng-zorro-antd/collapse';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzPopconfirmModule} from 'ng-zorro-antd/popconfirm';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {NzPaginationModule} from 'ng-zorro-antd/pagination';

export const NG_ZORRO_MODULES = [
    NzGridModule,
    NzInputModule,
    NzRadioModule,
    NzCardModule,
    NzTableModule,
    NzSkeletonModule,
    NzBackTopModule,
    NzDividerModule,
    NzButtonModule,
    NzCheckboxModule,
    NzSelectModule,
    NzLayoutModule,
    NzMenuModule,
    NzIconModule,
    NzAnchorModule,
    NzBreadCrumbModule,
    NzDatePickerModule,
    NzCarouselModule,
    NzModalModule,
    NzDrawerModule,
    NzOverlayModule,
    NzTabsModule,
    NzEmptyModule,
    NzAffixModule,
    NzCollapseModule,
    NzToolTipModule,
    NzSpinModule,
    NzPopconfirmModule,
    NzTagModule,
    NzPaginationModule
];
