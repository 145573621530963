import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NzModalRef} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-pop-message-box',
  templateUrl: './pop-message-box.component.html',
  styleUrls: ['./pop-message-box.component.scss']
})
export class PopMessageBoxComponent implements OnInit {

    constructor(private modal: NzModalRef) { }

    @Input() type: string;
    @Input() customTypeImg: string;
    @Input() customTypeImgCss: string;
    @Input() titleText: string;
    @Input() bodyText: string;
    @Input() confirmBtn = false;
    @Input() confirmBtnHtml: string;
    @Input() cancelBtn = false;
    @Input() cancelBtnHtml: string;
    @Input() bodyTextAlignment: string;
    @Input() bodyButtonAlignment: string;
    @Input() footerButtonList: Array<{label: string, value: string, type: string}> = [];

    @Output() event: EventEmitter<any> = new EventEmitter<any>();

    readonly BUTTON_TYPE = {
        default: 'default',
        primary: 'primary'
    };

    textAlignment = 'text-center';
    buttonAlignment = 'text-center';
    ngOnInit(): void {

        if (this.confirmBtn && this.cancelBtn) {
            this.textAlignment = 'text-left';
            this.buttonAlignment = 'text-right';
        }
        if (!this.confirmBtn && !this.cancelBtn) {
            this.confirmBtn = true;
        }
        if (this.bodyTextAlignment === 'left') {
            this.textAlignment = 'text-left';
        }
        if (this.bodyButtonAlignment === 'right') {
            this.buttonAlignment = 'text-right';
        }
    }

    destroyModal(): void {
        this.modal.destroy();
    }

    emitData(data: any) {
        // console.log('this is within alert box component');
        this.modal.destroy(data);
    }

    emitCustomData(value: string): void {
        this.modal.destroy({[value]: true});
    }

}
