<ng-container *nzModalTitle>
    <h4 class="mb-0">Forget Password</h4>
</ng-container>

<div class="text-center mb-4">
    <img src="assets/images/account/pc-question.svg" alt="IFAST FM"/>
</div>

<div class="text-center text-sm">
    <p class="mb-1 font-weight-bold">Reset Password</p>
    <p class="mb-0">For enhanced security, you are required to enter a 6-digit OTP to proceed with your login. The OTP will be sent to your registered email address on our record.</p>
    <p class="my-3">Enter the 6-digit code sent to <b>{{ email }}</b></p>
</div>
<div class="text-center mb-2">
    <p class="otp-prefix">{{ otpPrefix }} -</p>
    <div class="text-xs d-flex justify-content-center">
        <div>
            <ng-otp-input [config]="otpInputConfig" (onInputChange)="onOtpChange($event)"></ng-otp-input>
            <div class="text-right mt-1">
                <span *ngIf="isSendOTP" class="text-black-50">Resend OTP ({{otpCountdown}})</span>
                <a *ngIf="!isSendOTP" class="text-right text-xs text-primary" (click)="sendResetPasswordEmailOtp()">Resend OTP</a>
            </div>
        </div>
    </div>
</div>

<ng-container *nzModalFooter>
    <div class="text-center">
        <button [nzLoading]="submitLoading"
                nz-button
                [disabled]="!otpPrefix || otpValue.length !== 6"
                nzType="primary"
                (click)="changePassword()"
        >Submit
        </button>
    </div>
</ng-container>

