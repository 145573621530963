export function genRouterLink(...link: Array<string>) {
  if (!link) {
    return link;
  }

  if (Array.isArray(link) && link.length > 1) {
    return `/${link.join('/')}`;
  } else {
    return link[0].startsWith('/') ? `${link}` : `/${link}`;
  }
}
