import {Component, Input, OnInit} from '@angular/core';
import {AccountRestService} from '@api/service/api/account.rest.service';
import {SubHandlingService} from '@modules/shared-lib/common/service/subscription-handling.service';
import {PopMessageService} from '@service/pop-message.service';
import {NzModalRef} from 'ng-zorro-antd/modal';
import {AppConstant} from '@constant/app.constant';
import {tap} from 'rxjs/operators';
import {IResponse} from '@modules/shared-lib/common/model/i-response';
import {VerifyUserRequestModel} from '@model/account/verify-user-request.model';
import {AuthService} from '@service/auth/auth.service';
import {PresessionModel} from '@model/account/fm-client.model';
import {Router} from '@angular/router';
import {RouteConstant} from '@constant/route.constant';
import {BaseOtpComponent} from "@modules/shared-lib/common/base-otp/base-otp.component";

@Component({
    selector: 'app-forgot-password-otp',
    templateUrl: './forgot-password-otp.component.html',
    styleUrls: ['./forgot-password-otp.component.less'],
    providers: [SubHandlingService]
})
export class ForgotPasswordOtpComponent extends BaseOtpComponent implements OnInit {

    @Input() nric = '';
    @Input() username = '';
    @Input() password = '';

    email = '';
    submitLoading = false;

    constructor(
        private modal: NzModalRef,
        private subHandlingService: SubHandlingService,
        private popMessageService: PopMessageService,
        private authService: AuthService,
        private accountRestService: AccountRestService,
        private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {
        this.initOtp();

        this.sendResetPasswordEmailOtp();
    }

    closeModal() {
        this.modal.close();
    }

    sendResetPasswordEmailOtp() {
        if (this.nric && this.username) {
            this.clearOtpInput();
            const verifyUserRequestModel: VerifyUserRequestModel = {
                username: this.username,
                nric: this.nric
            };
            this.changeCountdownFlag();
            this.subHandlingService.once(
                this.accountRestService.sendResetPasswordEmailOtp(verifyUserRequestModel).pipe(tap((res) => {
                    if (res && res.data && res.status === AppConstant.RESPONSE_SUCCESS) {
                        // return format: otpPrefix-maskedEmail
                        let result: string[] = res.data?.split('-');
                        this.otpPrefix = result[0];
                        this.email = result[1];
                    } else {
                        this.popMessageService.openNewPopUpErrorModal('One Time Password Request Error').then();
                    }
                }, () => {
                    this.popMessageService.openNewPopUpErrorModal('One Time Password Request Error').then();
                }))
            );
        }
    }

    changePassword() {
        if (this.password && this.otpValue.length === 6) {
            this.submitLoading = true;
            const verifyUserRequestModel: VerifyUserRequestModel = {
                username: this.username,
                nric: this.nric,
                otpPrefix: this.otpPrefix
            };
            this.subHandlingService.once(
                this.authService.getPresession().pipe(tap((res: PresessionModel) => {
                    if (res) {
                        const rsa = this.authService.getNewRsaEngineWithKeys(res.publicKey, res.sid, res.randomNumber);
                        const encryptedPassword = rsa.encryptPIN1(this.password);
                        const encryptedOtp = rsa.encryptPIN1(this.otpValue);

                        verifyUserRequestModel.encryptedPassword = encryptedPassword;
                        verifyUserRequestModel.encryptedOtp = encryptedOtp;
                        verifyUserRequestModel.e2eeRandom = res.randomNumber;

                        this.subHandlingService.once(
                            this.accountRestService.resetPassword(verifyUserRequestModel).pipe(tap((subRes: IResponse<boolean>) => {
                                if (subRes && subRes.data && subRes.status === AppConstant.RESPONSE_SUCCESS) {
                                    this.openPasswordSuccessUpdateModal();
                                    this.closeModal();
                                } else {
                                    if (subRes.errorMessage) {
                                        this.popMessageService.openNewPopUpErrorModal(subRes.errorMessage).then();
                                    } else {
                                        this.popMessageService.openNewPopUpErrorModal('Your One-Time Password (OTP) is invalid or expired. Please enter a valid OTP or click "resend" OTP to get a new OTP.').then();
                                    }
                                }
                                this.submitLoading = false;
                            }, () => {
                                this.popMessageService.openNewPopUpErrorModal(
                                    'Your One-Time Password (OTP) is invalid or expired. Please enter a valid OTP or click "resend" OTP to get a new OTP.'
                                ).then();
                                this.submitLoading = false;
                            }))
                        );
                    }
                }, () => {
                    this.submitLoading = false;
                }))
            );
        }
    }

    openPasswordSuccessUpdateModal() {
        this.popMessageService.openNewPopUpModal({
            titleText: 'Password Successfully Updated',
            confirmBtn: true,
            confirmBtnHtml: 'Return To Login',
            type: 'success',
        }).then((res) => {
            if (res?.confirm) {
                this.router.navigate([RouteConstant.LOGIN]).then();
            }
        });
    }

}
