import {Directive, ElementRef, EventEmitter, HostListener, Input, Output, } from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[outsideClick]'
})
export class OutsideClickDirective {
    @Output()
    outsideClick: EventEmitter<MouseEvent> = new EventEmitter();

    @Input()toggleOutsideClick = false;

    constructor(private elementRef: ElementRef) {
    }

    @HostListener('document:mousedown', ['$event'])
    onClick(event: MouseEvent): void {
        console.log(this.toggleOutsideClick);
        if (!this.elementRef.nativeElement.contains(event.target) && this.toggleOutsideClick) {
            this.outsideClick.emit(event);
        }
    }
}
