<div class="ui-notes" [ngClass]="{ 'without-box-shadow' : !boxShadow }">
    <nz-collapse>
        <nz-collapse-panel [nzHeader]="headerTpl" [nzActive]="true">
            <ng-template #headerTpl>
                <h5 [ngClass]="{'text-sm': (isMobile$ | async)}" class="mb-0">{{ title }}</h5>
            </ng-template>
            <ng-content></ng-content>
        </nz-collapse-panel>
    </nz-collapse>
</div>

