<div class="breakcrumb-container flex {{ (isMobile$ | async)? 'px-4':'' }}" >
    <a routerLink="/dashoard">Home</a>
    <div class="separator">
        <ng-container *ngTemplateOutlet="separatorTemplate"></ng-container>
    </div>
    <xng-breadcrumb [separator]="separatorTemplate">
        <ng-container *xngBreadcrumbItem="let breadcrumb; let info = info">
            <ng-container>{{ breadcrumb }}</ng-container>
            {{info}}
        </ng-container>
    </xng-breadcrumb>
    <ng-template #separatorTemplate>
        /
    </ng-template>
</div>
