import {FmClientModel} from '@model/account/fm-client.model';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {StateConstant} from '@constant/state.constant';
import {LoginSuccess, Logout, SetFirstTimeLogin, SetFmClient, SetTwoFA} from '../action/auth.action';


export interface AuthStateModel {
    access_token: string;
    refresh_token: string;
    fmClientModel: FmClientModel;
    twoFaFlag: string;
    isFirstTimeLogin: string;
    submitWithoutApproval: string;
}

const stateDefaults: AuthStateModel = {
    access_token: '',
    refresh_token: '',
    fmClientModel: {},
    twoFaFlag: '',
    isFirstTimeLogin: '',
    submitWithoutApproval: 'N'
};

@State<AuthStateModel>({
    name: StateConstant.AUTH,
    defaults: stateDefaults
})
@Injectable()
export class AuthState {
    @Selector()
    static getAuthData(state: AuthStateModel): AuthStateModel {
        return {
            ...state
        };
    }

    @Selector()
    static isAuthenticated(state: AuthStateModel): boolean {
        return !!state.access_token && !!state.refresh_token && !!state.fmClientModel;
    }


    @Selector()
    static getAccessToken(state: AuthStateModel): string {
        return state.access_token;
    }

    @Selector()
    static getRefreshToken(state: AuthStateModel): string {
        return state.refresh_token;
    }

    @Selector()
    static getFmClient(state: AuthStateModel): FmClientModel {
        return <FmClientModel>state.fmClientModel;
    }

    @Selector()
    static getRightList(state: AuthStateModel): string[] {
        return state.fmClientModel?.rightList;
    }

    @Selector()
    static getIsTwoFaRegister(state: AuthStateModel): string {
        return state.fmClientModel?.isTwoFaRegister;
    }

    @Selector()
    static getIsFirstTimeLogin(state: AuthStateModel): string {
        return state.fmClientModel?.isFirstTimeLogin;
    }

    @Selector()
    static getClientName(state: AuthStateModel): string {
        return state.fmClientModel?.fullName;
    }

    @Selector()
    static getLastLoginDate(state: AuthStateModel): Date {
        return state.fmClientModel?.lastLoginDate;
    }

    @Selector()
    static hasSubmitWithoutApprovalRight(state: AuthStateModel): boolean {
        return state.submitWithoutApproval === 'Y';
    }

    @Selector()
    static getClientEmail(state: AuthStateModel): string {
        return state.fmClientModel.email;
    }

    @Action(LoginSuccess)
    loginSuccess({patchState}: StateContext<AuthStateModel>, action: LoginSuccess) {
        patchState({
            access_token: action.auth.access_token,
            refresh_token: action.auth.refresh_token,
            fmClientModel: action.auth.fmClientModel,
            twoFaFlag: action.auth.fmClientModel.isTwoFaRegister,
            isFirstTimeLogin: action.auth.fmClientModel.isFirstTimeLogin,
            submitWithoutApproval: action.auth.fmClientModel.submitWithoutApproval,
        });
    }

    @Action(SetFmClient)
    setCpUserPersonalDetails({patchState}: StateContext<AuthStateModel>, action: SetFmClient) {
        patchState({
            fmClientModel: action.fmClientModel
        });
    }

    @Action(SetTwoFA)
    setTwoFaFlag({patchState}: StateContext<AuthStateModel>, action: SetTwoFA) {
        patchState({
            twoFaFlag: action.flag
        });
    }

    @Action(SetFirstTimeLogin)
    setFirstTimeLoginFlag({patchState}: StateContext<AuthStateModel>, action: SetFirstTimeLogin) {
        patchState({
            isFirstTimeLogin: action.flag
        });
    }

    @Action(Logout)
    logout({setState}: StateContext<AuthStateModel>) {
        setState({
            ...stateDefaults
        });
    }
}
