export class ColorConstant {
    public static readonly HIGHCHART_COLOR: Array<string> = [
        '#EB648B',
        '#2F8DFA',
        '#1FD0BF',


        '#F8C753',
        '#EB7E30',
        '#A93790',
        '#049587',
        '#1553B6',
        '#55008C',
        '#8B572A',
        '#F0B900',
        '#868686',
        '#A0B990',
        '#0e5fd8',
        '#ffc3a0',
        '#133337',
        '#3cc47c',
        '#e24242',
        '#4484ce',
        '#6e3667',
        '#f81b84',
        '#0b3c5d',
        '#FF7600',
        '#3C61FF',
        '#61DE1E',
        '#00B2A9',
        '#E8D4A2',
        '#FF00BF',
        '#DD0000',
        '#C9C3E6',
        '#70b4d0',
        '#A8BECE',
        '#4bad31',
        '#f5dc00',
        '#813772',
        '#d6006e',
        '#00b27f',
        '#ff4500',
        '#d3ffce',
        '#0ba6ab',
        '#98afc0',
        '#6b0f1a',
    ];

    public static readonly AREAFILL_COLOR: Array<string> = [
        'rgba(235,100,139,0.71)',
        'rgba(47,141,250,0.71)',
        'rgba(31,208,191,0.71)',
        'rgba(248,199,83,0.71)',
        'rgba(235,126,48,0.71)',
        'rgba(169,55,144,0.71)',
        'rgba(4,149,135,0.71)',
        'rgba(21,83,182,0.71)',
        'rgba(85,0,140,0.7)',
        'rgba(139,87,42,0.71)',
        'rgba(240,185,0,0.7)',
        'rgba(134,134,134,0.71)',
        'rgba(160,185,144,0.7)',
        'rgba(14,95,216,0.7)',
        'rgba(255,195,160,0.71)',
        'rgba(19,51,55,0.71)',
        'rgba(60,196,124,0.71)',
        'rgba(226,66,66,0.71)',
        'rgba(68,132,206,0.71)',
        'rgba(110,54,103,0.71)',
        // TODO CONT OPACITY
        '#f81b84',
        '#0b3c5d',
        '#FF7600',
        '#3C61FF',
        '#61DE1E',
        '#00B2A9',
        '#E8D4A2',
        '#FF00BF',
        '#DD0000',
        '#C9C3E6',
        '#70b4d0',
        '#A8BECE',
        '#4bad31',
        '#f5dc00',
        '#813772',
        '#d6006e',
        '#00b27f',
        '#ff4500',
        '#d3ffce',
        '#0ba6ab',
        '#98afc0',
        '#6b0f1a',
    ];

    // Portfolio Planner plot color
    public static readonly PORTFOLIO_PLOT_COLOR: Array<string> = [
        '#FF7600',
        '#3C61FF',
        '#61DE1E'
    ];
    public static readonly ISLAMIC_BACKTEST_CHART_COLOR: Array<string> = [
        '#D800FF',
        '#23AEB4',
    ];

    public static readonly DASHBOARD_CHART_COLOR: Array<string> = [
        '#67ACB0',
        '#F78D1F',
        '#0F494C',
        '#6D475D',
        '#900C2F'
    ]

    public static readonly DASHBOARD_AREAFILL_COLOR: Array<string> = [
        'rgba(103, 172, 176, 0.71)',
        'rgba(247,141,31,0.71)',
        'rgba(15,73,76,0.71)',
        'rgba(109,71,93,0.71)',
        'rgba(144,12,47,0.71)'
    ]
}

