import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {RightsService} from '@service/auth/rights.service';
import {RouteConstant} from '@constant/route.constant';
import {AuthService} from '@service/auth/auth.service';
import {PopMessageService} from '@service/pop-message.service';

@Injectable({
    providedIn: 'root'
})
export class RightsGuard implements CanActivate {


    constructor(
        private rightsService: RightsService,
        private router: Router,
        private authService: AuthService,
        private popMessageService: PopMessageService,
    ) {
    }
    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const rights = route.routeConfig?.data?.right;
        console.log('rights', rights);

        if (rights && rights.length > 0) {
            if (this.hasAccess(rights)) {
                return true;
            } else {
                const currentPath = route.routeConfig?.path;
                console.log('current path', currentPath);
                if (currentPath && currentPath.includes(RouteConstant.DASHBOARD)) {
                    // logout and prompt error
                    this.authService.logout();
                    this.popMessageService.openPostLoginPopUpErrorModel('Error', 'You do not have permission to access this page.').then();
                    return;
                } else {
                    // prompt error
                    this.popMessageService.openPostLoginPopUpErrorModel('Error', 'You do not have permission to access this page.').then();
                    // order approval dont route to dashboard
                    if (!currentPath || !this.router.url.includes(RouteConstant.ORDER_APPROVAL)) {
                        this.router.navigate([RouteConstant.DASHBOARD]).then();
                    }
                    return false;
                }
            }
        }
        return true;
    }

    hasAccess(rights: string[]) {
        return this.rightsService.checkRights(rights);
    }

}
