<div>
    <div class="mb-4">
        <div *ngIf="type">
            <i *ngIf="type === 'success'" nz-icon nzType="check-circle" nzTheme="fill" class="text-profit pop-message-icon"></i>
            <i *ngIf="type === 'error'" nz-icon nzType="close-circle" nzTheme="fill" class="text-loss pop-message-icon"></i>
        </div>
        <div *ngIf="customTypeImg">
            <img [ngClass]="customTypeImgCss"  [src]="customTypeImg" alt="IFAST FM"/>
        </div>
        <h4 *ngIf="titleText" class="mb-1">{{ titleText }}</h4>
        <p *ngIf="bodyText" class="text-base">{{ bodyText }}</p>
    </div>
    <div *ngIf="confirmBtn || cancelBtn" class="d-flex justify-content-center align-items-center">
        <button *ngIf="cancelBtn"
                (click)="emitData({cancel: true})"
                class="mr-2"
                nz-button
                nzType="default"
                nzSize="default"
        >{{ cancelBtnHtml }}</button>
        <button *ngIf="confirmBtn"
                (click)="emitData({confirm: true})"
                nz-button
                nzType="primary"
                nzSize="default"
        >{{ confirmBtnHtml }}</button>
    </div>
</div>
