<ng-container *nzModalTitle>
    <h4 class="mb-0">Forget Username</h4>
</ng-container>

<div class="bg-black-3 p-3 rounded-lg shadow-sm mb-4">
    <h4 class="text-base mb-2">Note(s)</h4>
    <p class="text-xs text-black mb-0">1. Please insert your registered email address.</p>
    <p class="text-xs text-black mb-0">2. Please insert your NRIC</p>
</div>

<div class="text-center mb-4">
    <img src="assets/images/account/pc-question.svg" alt="iFAST FM">
</div>

<form nz-form [formGroup]="form" class="form-container">
    <div class="mb-2">
        <nz-form-item class="mb-0">
            <nz-form-control>
                <app-ui-input nzType="email" formControlName="email" nzPlaceHolder="Email"></app-ui-input>
            </nz-form-control>
        </nz-form-item>
        <div *ngIf="emailControl.errors?.pattern" class="d-flex align-items-center text-xs text-warning">
            <span class="text-warning mr-1" size="small" nz-icon nzType="info-circle" nzTheme="fill"></span>Please enter a valid email.
        </div>
    </div>
    <div>
        <nz-form-item class="mb-0">
            <nz-form-control>
                <app-ui-input-wrapper>
                    <nz-input-group>
                        <app-ui-input nzType="text" formControlName="nric" nzPlaceHolder="NRIC"></app-ui-input>
                    </nz-input-group>
                </app-ui-input-wrapper>
            </nz-form-control>
        </nz-form-item>
    </div>
</form>

<ng-container *nzModalFooter>
    <div class="text-center">
        <button [disabled]="form.invalid"
                nz-button
                [nzLoading]="submitLoading"
                nzType="primary"
                (click)="sendForgetUsernameEmail()"
        >Submit
        </button>
    </div>
</ng-container>
