import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
  selector: '[numeric]', standalone: true
})
export class NumericDirective {
  @Input() numeric: string; // number | decimal | currency
  @Input() decimalPlace: number;
  @Input() displayWithDp = false;

  private regex: any = {
    number: new RegExp(/^\d+$/),
    decimal: new RegExp(/^[0-9]*\.?[0-9]*$/g),
    currency: new RegExp(/^[0-9]*\.?([0-9]?[0-9]?$)*$/g),
  };

  private specialKeys: any = {
    number: [
      'Backspace',
      'Delete',
      'Tab',
      'Escape',
      'Enter',
      'Home',
      'End',
      'ArrowLeft',
      'ArrowRight',
      'Clear',
    ],
    decimal: [
      'Backspace',
      'Delete',
      'Tab',
      'Escape',
      'Enter',
      'Home',
      'End',
      'ArrowLeft',
      'ArrowRight',
      'Clear',
    ],
    currency: [
      'Backspace',
      'Delete',
      'Tab',
      'Escape',
      'Enter',
      'Home',
      'End',
      'ArrowLeft',
      'ArrowRight',
      'Clear',
    ],
  };

  constructor(private element: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys[this.numeric]?.indexOf(event?.key) !== -1) {
      return;
    }

    let regex = this.regex[this.numeric];

    if (this.decimalPlace != null) {
      const regexString: string =
        '^[0-9]*\\.?(\\d{0,' + this.decimalPlace + '}?$)*$';
        regex = new RegExp(regexString);
    }

    const current: string = this.element.nativeElement.value;
    const insertPosition = (event?.target as any)?.selectionStart;
    let next: string;

    if (insertPosition !== 0 && insertPosition !== (current?.length - 1)) {
      next = [current?.slice(0, insertPosition), event?.key, current?.slice(insertPosition)].join('');
    } else if (insertPosition === 0) {
      next = event?.key?.concat(current);
    } else {
      next = current.concat(event?.key);
    }

    if (next && !String(next).match(regex)) {
      event.preventDefault();
    }
  }

  @HostListener('blur')
  onBlur() {
    if (this.displayWithDp) {
      const current = this.element.nativeElement.value;
      const fractionDigits = this.numeric === 'currency' ? 2 : this.decimalPlace;
      const formatted = isNaN(current) ? current : Number(current).toFixed(fractionDigits);
      let regex = this.regex[this.numeric];

      if (this.decimalPlace != null) {
        const regexString: string =
          '^[0-9]*\\.?(\\d{0,' + this.decimalPlace + '}?$)*$';
          regex = new RegExp(regexString);
      }

      if (String(formatted).match(regex)) {
        this.element.nativeElement.value = formatted;
      }
    }
  }

  @HostListener('focus')
  onFocus() {
    if (this.displayWithDp) {
      const current = this.element.nativeElement.value;
      const fractionDigits = this.numeric === 'currency' ? 2 : this.decimalPlace;
      const formatted = isNaN(current) ? current : Number(current).toFixed(fractionDigits);
      const valueWhenEmpty = Number(null).toFixed(fractionDigits);

      if (valueWhenEmpty === formatted) {
        this.element.nativeElement.value = null;
      }
    }
  }
}
