import {Component, ViewChild} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Config} from 'ng-otp-input/lib/models/config';
import {NgOtpInputComponent} from "ng-otp-input";

@Component({
    selector: 'app-base-otp',
    standalone: true,
    imports: [CommonModule],
    template: 'NO UI TO BE FOUND HERE',
})
export abstract class BaseOtpComponent {

    // For OTP
    otpPrefix = '';
    otpInputConfig: Config = {
        length: 6,
        allowNumbersOnly: true,
        isPasswordInput: false,
        disableAutoFocus: true,
        inputStyles: {
            'font-size': '14px',
            'border': '1px solid #D5E0E8',
        }
    };
    otpValue = '';
    isSendOTP = false;
    otpCountdown: number;
    countDownFlag = false;
    countdownInterval: any;
    readonly DEFAULT_OTP_COUNTDOWN: number = 50;
    @ViewChild(NgOtpInputComponent, { static: false}) ngOtpInput:NgOtpInputComponent;

    initOtp() {
        this.otpValue = '';
        this.isSendOTP = false;
        this.otpCountdown = null;
        this.otpPrefix = '';
    }

    changeCountdownFlag() {
        this.isSendOTP = true;
        this.countDownFlag = false;
        this.setCountdownTimer();
    }

    setCountdownTimer() {
        this.otpCountdown = this.DEFAULT_OTP_COUNTDOWN;
        this.countdownInterval = setInterval(() => {
            this.otpCountdown--;
            if (this.otpCountdown <= 0) {
                this.countDownFlag = true;
                this.isSendOTP = false;
                this.resetCountdownTimer();
            }
        }, 1000);
    }

    private resetCountdownTimer() {
        clearInterval(this.countdownInterval);
    }

    onOtpChange($event: string) {
        this.otpValue = $event;
    }

    clearOtpInput() {
        this.otpPrefix = '';
        this.ngOtpInput?.setValue('');
    }
}
