import {HttpErrorResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AppConstant} from 'src/app/constant/app.constant';
import {IResponse} from '../../model/i-response';

/**
 * map a HTTP response to array
 * @returns an observable with array of data or empty array
 */
export function mapArrayResponse() {
  return function<T>(source: Observable<IResponse<T>>): Observable<T | Array<any>> {
    return source.pipe(map((res: IResponse<T>) => (Array.isArray(res.data) ? res.data : [])));
  };
}


/**
 * map a HTTP response to object
 * @returns an observable with data object or empty object
 */
export function mapObjectResponse() {
  return function<T>(source: Observable<IResponse<T>>): Observable<T | any> {
    return source.pipe(map((res: IResponse<T>) => {
      return res.status === AppConstant.RESPONSE_SUCCESS && res.data ? res.data : {};
    }));
  };
}

/**
 * handles HTTP error response gracefully
 * @returns an observable with error data object or empty object
 */
export function catchErrResponse() {
  return function<T>(source: Observable<IResponse<T> | any>): Observable<T | any> {
    return source.pipe(catchError((res?: HttpErrorResponse) => of(res.error)));
  };
}
