<nz-affix nzOffsetTop="0" class="header-affix print:hidden" [ngClass]="{'header-clear':isClearBg, 'scrolled':isScrolled}">
    <!-- Web View -->
        <div class="pre-login-header">
            <div class="container">
                <app-web-scaffold>
                    <div class="flex justify-between items-center">
                        <a routerLink="/">
                            <img src="assets/images/{{isClearBg && !isScrolled?'ifm-white-logo-2x':'ifm-logo@2x'}}.svg" alt="iFast Fund Management" class="pre-header-logo"/>
                        </a>
                        <ul class="nav-menu" nz-menu nzTheme="light" nzMode="horizontal">
                            <ng-container *ngFor="let nav of preNavData">
                                <li nz-menu-item [routerLink]="[genRouterLink(nav.url)]" nzMatchRouter="true">
                                    <span class="font-semibold">{{ nav.title }}</span>
                                </li>
                            </ng-container>
                            <button *ngIf="!isAuthenticated" nz-button nzType="primary" class="ml-4" (click)="navigateToLogin()">
                                Login
                            </button>
                            <ng-container *ngIf="isAuthenticated">
                                <app-client-profile-menu [clientName]="(clientName$ | async) || ''" [isPostLoginMenu]="false" (logoutEvent)="logout()"></app-client-profile-menu>
                            </ng-container>
                        </ul>
                    </div>
                </app-web-scaffold>
                <app-mobile-scaffold>
                    <div class="flex justify-between items-center gap-4 py-2">
                        <a [routerLink]="['/']">
                            <img src="assets/images/{{isClearBg && !isScrolled?'ifm-white-logo-2x':'ifm-logo@2x'}}.svg" alt="iFast Fund Management Logo" class="mobile-header-logo"/>
                        </a>
                        <div>
                            <button nz-button nzType="primary" (click)="showDrawer()" class="ant-btn-sm">
                                <i nz-icon nzType="menu" nzTheme="outline"></i>
                            </button>
                        </div>
                    </div>
                </app-mobile-scaffold>
            </div>
        </div>
</nz-affix>

<app-mobile-menu-drawer
    [drawerVisible]="drawerVisible"
    [isAuthenticated]="isAuthenticated"
    [clientName]="(clientName$ | async)"
    [mobilePostNavData]="mobilePostNavData"
    (closeDrawer)="closeDrawer()"
    (logoutEvent)="logout()"
></app-mobile-menu-drawer>
