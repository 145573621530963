<!-- Default Template -->
<ng-container *ngIf="!hasPrefixAndSuffix">
  <app-ml-input [nzPlaceHolder]="nzPlaceHolder" [nzData]="inputModel" [nzWrap]="nzWithMlWrapper" [nzSize]="nzSize">
    <div class="ui-input-customize-autofill">
      <input [ngClass]="nzClass" ui-input nz-input [numeric]="nzNumericType" [decimalPlace]="nzDecimal" [displayWithDp]="nzDisplayWithDp" [disabled]="nzDisabled" [placeholder]="nzPlaceHolder" [nzSize]="nzSize" required
            [(ngModel)]="inputModel" (ngModelChange)="onChange($event)" (blur)="onTouch()" [type]="nzType" [autocomplete]="autocomplete" [attr.maxlength]="nzMaxLength" [readOnly]="nzReadonly" [autofocus]="true" #input>
    </div>
  </app-ml-input>
</ng-container>

<!-- Has Prefix and Suffix -->
<ng-container *ngIf="hasPrefixAndSuffix">
    <nz-input-group class="ui-input-group ui-input-customize-autofill"
                    [ngClass]="nzDisabled && nzDisabledBackground ? 'disabled-input-group ' + (nzAccountOpeningDisabled? 'account-opening-disabled-input-group ' + nzClass : nzClass) : nzClass"
                    [nzSuffix]="nzSuffix"
                    [nzPrefix]="nzPrefix"
                    [nzSize]="nzSize"
    >
        <app-ml-input [nzPlaceHolder]="nzPlaceHolder" [nzData]="inputModel" [nzWrap]="nzWithMlWrapper" [nzSize]="nzSize">
            <input ui-input nz-input [numeric]="nzNumericType" [decimalPlace]="nzDecimal" [disabled]="nzDisabled" [displayWithDp]="nzDisplayWithDp" [placeholder]="nzPlaceHolder" [nzSize]="nzSize" [(ngModel)]="inputModel"
                   (ngModelChange)="onChange($event)" (blur)="onTouch()" [type]="nzType" [ngClass]="nzClass" [autocomplete]="autocomplete" [attr.maxlength]="nzMaxLength" [readOnly]="nzReadonly" [autofocus]="true" #input>
        </app-ml-input>
    </nz-input-group>
</ng-container>
