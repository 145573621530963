import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { GlobalState } from '@core/store';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'app-mobile-scaffold',
    template: `
        <ng-container *ngIf="show$ | async">
            <ng-content></ng-content>
        </ng-container>
    `,
    encapsulation: ViewEncapsulation.None,
})
export class MobileScaffoldComponent implements OnInit {
    show$: Observable<boolean>;

    private bootstrapped = false;

    constructor(private store: Store, private cdRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.show$ = this.store.select(GlobalState.isMobile).pipe(
            tap(() => {
                if (this.bootstrapped) {
                    this.cdRef.detectChanges();
                } else {
                    this.bootstrapped = true;
                }
            })
        );
    }
}
