import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Select} from '@ngxs/store';
import {GlobalState} from '@core/store';
import {Observable} from 'rxjs';
import {PostBreadcrumbService} from "@service/post-breadcrumb.service";
import {DynamicCssLoaderService} from "@api/service/template/load-css.service";
import {OverlayService} from "@api/service/template/overlay.service";

@Component({
  selector: 'app-post-login-layout',
  templateUrl: './post-login-layout.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./post-login-layout.component.less']
})
export class PostLoginLayoutComponent implements OnInit, OnDestroy {

    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
    showBreadcrumb = true;
    applyContainer = true;
    applyContainerFluid = false;
    constructor(
        private cssLoader: DynamicCssLoaderService,
        private overlayService: OverlayService,
        private postBreadcrumbService: PostBreadcrumbService,
    ) {}

    ngOnInit(): void {
        this.cssLoader.loadCss('default-revamp.css', 'default-style-revamp');
        this.subscribePostBreadcrumbService();
        this.overlayService.removeOverlay();
    }

    ngOnDestroy() {
        this.overlayService.displayOverlay();
        this.cssLoader.removeCss('default-style-revamp');
    }

    subscribePostBreadcrumbService() {
        this.postBreadcrumbService.showBreadcrumbChange$.subscribe(show => {
            this.showBreadcrumb = show;
        });

        this.postBreadcrumbService.applyContainerChange$.subscribe(show => {
            this.applyContainer = show;
        });

        this.postBreadcrumbService.applyContainerFluidChange$.subscribe(show => {
            this.applyContainerFluid = show;
        });
    }
}
