import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {AsyncPipe, NgClass} from '@angular/common';
import {NzCollapseModule} from "ng-zorro-antd/collapse";
import {Select} from "@ngxs/store";
import {GlobalState} from "@core/store";
import {Observable} from "rxjs";

@Component({
    selector: 'app-ui-notes',
    standalone: true,
    templateUrl: './ui-notes.component.html',
    styleUrls: ['./ui-notes.component.less'],
    encapsulation: ViewEncapsulation.None,
    imports: [
        NgClass,
        NzCollapseModule,
        AsyncPipe
    ]
})
export class UiNotesComponent implements OnInit {
    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;

    @Input() title: string = 'Note(s)';
    @Input() boxShadow = true;

    ngOnInit(): void {
    }

}
