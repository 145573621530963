import { NgModule } from '@angular/core';
import {PopMessageBoxComponent} from '@modules/shared-lib/common/component/pop-message-box/pop-message-box.component';
import {SharedCommonModule} from '@modules/shared-lib/common/common.module';



@NgModule({
  declarations: [
      PopMessageBoxComponent
  ],
    imports: [
        SharedCommonModule

    ],
    exports: [
        PopMessageBoxComponent
    ]
})
export class PopMessageBoxModule { }
