import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RegexConstant} from '@constant/regex.constant';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {PopMessageService} from '@service/pop-message.service';
import {Router} from '@angular/router';
import {ForgotPasswordOtpComponent} from '@modules/auth/component/forgot-password-otp/forgot-password-otp.component';
import {AuthService} from '@service/auth/auth.service';
import {SubHandlingService} from '@modules/shared-lib/common/service/subscription-handling.service';
import {AccountRestService} from '@api/service/api/account.rest.service';
import {VerifyUserRequestModel} from '@model/account/verify-user-request.model';
import {tap} from 'rxjs/operators';
import {PresessionModel} from '@model/account/fm-client.model';
import {AppConstant} from '@constant/app.constant';
import {Select} from '@ngxs/store';
import {RouteConstant} from '@constant/route.constant';
import {GlobalState} from '@core/store';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-reset-password-modal',
    templateUrl: './reset-password-modal.component.html',
    styleUrls: ['./reset-password-modal.component.less'],
    providers: [SubHandlingService]
})
export class ResetPasswordModalComponent implements OnInit {
    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
    isMobile: boolean = false;
    @Input() title = '';
    @Input() type: 'resetPass' | 'forgotPass';
    @Input() username = '';
    @Input() nric = '';

    passwordForm: FormGroup;
    submitLoading = false;
    visiblePwd = false;
    visibleConfirmPwd = false;

    constructor(
        private fb: FormBuilder,
        private modal: NzModalRef,
        private popMessageService: PopMessageService,
        private nzModalService: NzModalService,
        private authService: AuthService,
        private subHandlingService: SubHandlingService,
        private accountRestService: AccountRestService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.initPasswordForm();
        this.isMobile$.subscribe((isMobile: boolean) => {
            this.isMobile = isMobile;
        })
    }

    closeModal() {
        this.modal.close();
    }

    initPasswordForm() {
        this.passwordForm = this.fb.group({
            password: [null,  [Validators.required, Validators.pattern(RegexConstant.REGEX_PASSWORD)]],
            confirmPass: [null, [Validators.required]]
        });
    }

    get passwordControl() {
        return this.passwordForm.get('password');
    }

    get confirmPassControl() {
        return this.passwordForm.get('confirmPass');
    }

    validateConfirmPassValue() {
        if (this.confirmPassControl.value && this.passwordControl.value !== this.confirmPassControl.value) {
            this.confirmPassControl.setErrors({custom: true});
            this.confirmPassControl.markAsTouched();
        } else {
            this.confirmPassControl.setErrors(null);
        }
    }

    submit() {
        if (this.passwordForm.valid) {
            switch (this.type) {
                case 'resetPass':
                    this.submitLoading = true;
                    this.resetPassword();
                    break;
                case 'forgotPass':
                    this.closeModal();
                    this.nzModalService.create({
                        nzWrapClassName: this.isMobile ? 'basic-ui-modal grey-modal' : 'basic-ui-modal grey-modal w650-screen',
                        nzCentered: true,
                        nzClosable: true,
                        nzMaskClosable: false,
                        nzContent: ForgotPasswordOtpComponent,
                        nzComponentParams: {
                            username: this.username,
                            nric: this.nric,
                            password: this.passwordControl.value
                        }
                    });
                    break;
            }
        }
    }

    resetPassword() {
        const verifyUserRequestModel: VerifyUserRequestModel = {};
        this.subHandlingService.once(
            this.authService.getPresession().pipe(tap((res: PresessionModel) => {
                if (res) {
                    const rsa = this.authService.getNewRsaEngineWithKeys(res.publicKey, res.sid, res.randomNumber);
                    verifyUserRequestModel.encryptedPassword = rsa.encryptPIN1(this.passwordControl.value);
                    verifyUserRequestModel.e2eeRandom = res.randomNumber;
                    verifyUserRequestModel.username = this.username;
                    this.subHandlingService.once(
                        this.accountRestService.resetPasswordFirstTimeLogin(verifyUserRequestModel).pipe(tap(subRes => {
                            if (subRes && subRes.data && subRes.status === AppConstant.RESPONSE_SUCCESS) {
                                this.authService.firstTimeLoginFlowEvent.emit(true);
                                this.openPasswordSuccessUpdateModal();
                                this.closeModal();
                            } else {
                                if (subRes.errorMessage) {
                                    this.popMessageService.openNewPopUpErrorModal(subRes.errorMessage).then();
                                }
                            }
                            this.submitLoading = false;
                        }, () => {
                            this.submitLoading = false;
                            this.popMessageService.openNewPopUpErrorModal('Reset Password Failed').then();
                        }))
                    );

                }
            }, () => {
                this.submitLoading = false;
            }))
        );
    }

    openPasswordSuccessUpdateModal() {
        this.popMessageService.openNewPopUpModal({
            titleText: 'Password Successfully Updated',
            confirmBtn: true,
            confirmBtnHtml: 'Go To Dashboard',
            type: 'success',
        }).then((res) => {
            if (res?.confirm) {
                this.router.navigate([RouteConstant.DASHBOARD]).then();
            }
        });
    }

}
