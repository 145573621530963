// default settings
export const environmentConfig: IEnvironmentModel = {
    production: true, // this is Angular's production build.
    utrial: false,
    test: false,
    trial: false,
    trial1: false,
    trial2: false,
    dev: false,
    live: false, // live (production) environment
    testMessage: '',
    tracking: false,
    url: '',
    apiUrl: '',
    lsUrl: ''
};


export interface IEnvironmentModel {
    production: boolean;
    utrial: boolean;
    test: boolean;
    trial: boolean;
    trial1: boolean;
    trial2: boolean;
    dev: boolean;
    live: boolean;
    testMessage: string;
    tracking: boolean;
    url: string;
    apiUrl: string;
    lsUrl: string;
}
