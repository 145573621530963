import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'format',
    standalone: true
})
export class FormatPipe implements PipeTransform {
    constructor() {}

    transform(value: any, textAppend?: string, replaceSymbol?: string): any {
        if (textAppend) {
            return value ? textAppend.replace(replaceSymbol, value) : '-';
        }
        return value ? value : '-';
    }
}
