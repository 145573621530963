<ng-container *nzModalTitle>
    <h4 class="mb-0">We are authenticating your login</h4>
</ng-container>

<div class="text-center">
    <img src="assets/images/account/pc-lock.svg" alt="iFAST FM">
</div>

<div class="mt-3 text-center text-sm">
    <p class="mb-1 font-weight-bold">Email OTP Registration</p>
    <p class="mb-0">In order to activate your registered 2FA email address, please enter below 6-digit OTP that was sent to your registered email address.</p>
    <p class="my-3">Enter the 6-digit code sent to <b>{{ this.clientEmail }}</b></p>
</div>
<div class="text-center mb-2">
    <p class="otp-prefix">{{ otpPrefix }} -</p>
    <div class="text-xs d-flex justify-content-center">
        <div>
            <ng-otp-input [config]="otpInputConfig" (onInputChange)="onOtpChange($event)"></ng-otp-input>
            <div class="text-right mt-1">
                <span *ngIf="isSendOTP" class="text-black-50">Resend OTP ({{otpCountdown}})</span>
                <a *ngIf="!isSendOTP" class="text-right text-xs text-primary" (click)="sendEmailOtp()">Resend OTP</a>
            </div>
        </div>
    </div>
</div>

<ng-container *nzModalFooter>
    <div class="text-center">
        <button
                [nzLoading]="verifyEmailOtpLoading"
                nz-button
                [disabled]="!otpPrefix || otpValue.length !== 6 || !clientEmail"
                nzType="primary"
                (click)="verifyEmailOtp()"
        >Verify
        </button>
    </div>
</ng-container>

