export enum BreakpointBelowEnum {
  XS = '(max-width: 480px)',
  SM = '(max-width: 576px)',
  MD = '(max-width: 768px)',
  LG = '(max-width: 992px)',
  XL = '(max-width: 1200px)',
  XXL = '(max-width: 1600px)'
}

export enum BreakpointAbove {
  XS = '(max-width: 575px)',
  SM = '(min-width: 576px)',
  MD = '(min-width: 768px)',
  LG = '(min-width: 992px)',
  XL = '(min-width: 1200px)',
  XXL = '(min-width: 1600px)'
}

export const MobileWebBreakpoint = '(max-width: 991px)';
