import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ClientUrlBasic} from '@api/url-constant/ClientUrlBasic';
import {environment} from '@env';

@Injectable({
    providedIn: 'root'
})
export class RestReportService {
    private BASE_URL: string = ClientUrlBasic.OUR_FUNDS;
    private API_SERVER = '';

    constructor(private http: HttpClient) {
        if (!environment.dev) {
            this.API_SERVER = environment.apiUrl;
        }
    }

    downloadTableAsExcel() {
        const httpHeaders = new HttpHeaders({
            'Accept': 'application/octet-stream'
        });
        return this.http.post(this.API_SERVER + this.BASE_URL + ClientUrlBasic.DOWNLOAD_FUND_LIST, {}, {headers: httpHeaders, responseType: 'arraybuffer'});
    }

    fmFundDailyPriceDownload(fundId, startDate, endDate) {
        const httpHeaders = new HttpHeaders({
            'Accept': 'application/octet-stream'
        });

        const data = {fundId: fundId, startDate: startDate, endDate: endDate};
        return this.http.post(this.dataToUrl(this.API_SERVER + this.BASE_URL + ClientUrlBasic.FM_FUND_DAILY_PRICE_DOWNLOAD, data), {}, {headers: httpHeaders, responseType: 'arraybuffer'});
    }

    public dataToUrl(url: string, data?: any) {
        if (data != null) {
            let isFirst = true;
            for (const objKey of Object.keys(data)) {
                if (isFirst) {
                    url += '?' + objKey + '=' + data[objKey];
                    isFirst = false;
                } else {
                    url += '&' + objKey + '=' + data[objKey];
                }
            }
        }
        return url;
    }
}
