<ul class="header-desktop-nav items-center" nz-menu nzTheme="light" nzMode="horizontal">
    <li nz-submenu routerLinkActive="active"
        nzPlacement="bottomRight"
        nzMenuClassName="header-desktop-submenu-cdk"
        [nzTitle]="titleTpl">
        <ul>
            <li *ngIf="!isPostLoginMenu" nz-menu-item routerLinkActive="active">
                <a [routerLink]="[genRouterLink(routeConstant?.DASHBOARD)]">
                    <span>Dashboard</span>
                </a>
            </li>
            <li nz-menu-item routerLinkActive="active" [nzMatchRouter]="true" >
                <a [routerLink]="[genRouterLink(routeConstant?.PROFILE_SETTINGS)]">
                    <span>Profile Settings</span>
                </a>
            </li>
            <li *ngIf="isPostLoginMenu" nz-menu-item routerLinkActive="active" [nzMatchRouter]="true">
                <a [routerLink]="[genRouterLink(routeConstant?.CONTACT_US)]">
                    <span>Contact Us</span>
                </a>
            </li>
            <li nz-menu-item routerLinkActive="active" [nzMatchRouter]="true" (click)="logout()">
                <span>Logout</span>
            </li>
        </ul>
    </li>
</ul>
<ng-template #titleTpl>
    <nz-avatar [nzText]="clientName[0] || ''" class="avatar"></nz-avatar>
    <span>{{ clientName }}</span>
    <span class="material-icons ml-2">keyboard_arrow_down</span>
</ng-template>
