import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, ActivatedRouteSnapshot, Event, NavigationEnd, Router} from '@angular/router';
import {filter, map, tap} from 'rxjs/operators';
import {IRouteData} from '@model/i-route.model';
import {PageConstant} from '@constant/page.constant';

@Injectable({
    providedIn: 'root'
})
export class PageService {
    private readonly DEFAULT_TITLE: string = PageConstant.PAGE_TITLE;

    constructor(private title: Title, private router: Router, private route: ActivatedRoute) {

    }

    listen() {
        this.router.events.pipe(
            filter((event: Event) => event instanceof NavigationEnd),
            map(() => this.getNearestRouteData(this.route.snapshot)),
            tap((routeData: IRouteData) => {
                this.setTitle(routeData?.nav?.title ? routeData?.nav?.title : routeData?.title);
                // this.setTitle(routeData?.title || routeData?.nav?.title);
            })
        ).subscribe();
    }

    private getNearestRouteData(route: ActivatedRouteSnapshot) {
        let data: IRouteData = {};

        if (route.data && Object.keys(route.data).length > 0) {
            data = {
                ...route.data
            };
        }

        if (route.children && route.children.length) {
            return this.getNearestRouteData(route.children[0]);
        } else {
            return data || {};
        }
    }

    setTitle(title: string) {
        this.title.setTitle(title ? `${title} | ${this.DEFAULT_TITLE}` : this.DEFAULT_TITLE);
    }
}
