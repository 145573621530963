import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {AppConstant} from '@constant/app.constant';


@Pipe({name: 'formatNumber'})
export class FormatNumberPipe implements PipeTransform {

    readonly TWO_DP = AppConstant.TWO_DP;

    constructor(private decimalPipe: DecimalPipe) {
    }

    transform(value: number): string | number {
        if (value === 0) {
            return 0;
        }

        if (typeof value === 'number') {
            return this.decimalPipe.transform(value, this.TWO_DP);
        } else if (value != null) {
            return value;
        } else {
            return '';
        }
    }

    precisionRound(number: number, precision: number): number {
        const factor = Math.pow(10, precision);
        return Math.round(number * factor) / factor;
    }
}
