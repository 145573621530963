import {Injectable} from '@angular/core';
import {ClientUrlBasic} from '@api/url-constant/ClientUrlBasic';
import {HttpService} from '@api/service/template/http.service';
import {Observable} from 'rxjs';
import {IResponse} from '@modules/shared-lib/common/model/i-response';
import {HttpParamsService} from '@api/service/template/http-params.service';
import {VerifyUserRequestModel} from '@model/account/verify-user-request.model';
import {FmClientModel, RequestOtpModel} from "@model/account/fm-client.model";

@Injectable({providedIn: 'root'})
export class AccountRestService {

    readonly BASE_URL = ClientUrlBasic.ACCOUNT;

    constructor(
        private httpService: HttpService,
        private paramService: HttpParamsService,
    ) {}

    validateEmail(email: string): Observable<IResponse<boolean>> {
        const params = this.paramService.buildHttpParams({email});
        return this.httpService.postIResponseWithParam(this.BASE_URL + ClientUrlBasic.IS_EMAIL_EXIST, params);
    }

    sendEmailOtp(requestOtpModel: RequestOtpModel): Observable<IResponse<string>> {
        return this.httpService.postIResponseWithBody(this.BASE_URL + ClientUrlBasic.SEND_EMAIL_OTP, requestOtpModel);
    }

    sendTwoFaEmailOtp(requestOtpModel: RequestOtpModel): Observable<IResponse<string>> {
        return this.httpService.postIResponseWithBody(this.BASE_URL + ClientUrlBasic.SEND_TWO_FA_EMAIL_OTP, requestOtpModel);
    }

    sendSmsOtp(requestOtpModel: RequestOtpModel): Observable<IResponse<string>> {
        return this.httpService.postIResponseWithBody(this.BASE_URL + ClientUrlBasic.SEND_SMS_OTP, requestOtpModel);
    }

    authenticateEmailOtp(verifyUserRequestModel: VerifyUserRequestModel): Observable<IResponse<boolean>> {
        return this.httpService.postIResponseWithBody(this.BASE_URL + ClientUrlBasic.AUTHENTICATE_EMAIL_OTP, verifyUserRequestModel);
    }

    authenticateSmsOtp(verifyUserRequestModel: VerifyUserRequestModel): Observable<IResponse<boolean>> {
        return this.httpService.postIResponseWithBody(this.BASE_URL + ClientUrlBasic.AUTHENTICATE_SMS_OTP, verifyUserRequestModel);
    }

    authenticateTwoFaOtp(verifyUserRequestModel: VerifyUserRequestModel): Observable<IResponse<boolean>> {
        return this.httpService.postIResponseWithBody(this.BASE_URL + ClientUrlBasic.AUTHENTICATE_TWO_FA_OTP, verifyUserRequestModel);
    }

    verifyEmailAndNric(verifyUserRequestModel: VerifyUserRequestModel): Observable<IResponse<boolean>> {
        return this.httpService.postIResponseWithBody(this.BASE_URL + ClientUrlBasic.VERIFY_BY_USERNAME_NRIC, verifyUserRequestModel);
    }

    sendForgetUsernameEmail(verifyUserRequestModel: VerifyUserRequestModel): Observable<IResponse<boolean>> {
        return this.httpService.postIResponseWithBody(this.BASE_URL + ClientUrlBasic.SEND_FORGET_USERNAME_EMAIL, verifyUserRequestModel);
    }

    sendResetPasswordEmailOtp(verifyUserRequestModel: VerifyUserRequestModel): Observable<IResponse<string>> {
        return this.httpService.postIResponseWithBody(this.BASE_URL + ClientUrlBasic.SEND_RESET_PASSWORD_EMAIL_OTP, verifyUserRequestModel);
    }

    resetPassword(verifyUserRequestModel: VerifyUserRequestModel): Observable<IResponse<boolean>> {
        return this.httpService.postIResponseWithBody(this.BASE_URL + ClientUrlBasic.RESET_PASSWORD, verifyUserRequestModel);
    }

    resetPasswordFirstTimeLogin(verifyUserRequestModel: VerifyUserRequestModel): Observable<IResponse<boolean>> {
        return this.httpService.postIResponseWithBody(this.BASE_URL + ClientUrlBasic.RESET_PASSWORD_FIRST_TIME_LOGIN, verifyUserRequestModel);
    }

    getPendingOrders() {
        return this.httpService.postIResponse(this.BASE_URL + ClientUrlBasic.GET_PENDING_ORDERS);
    }

    getProfile() {
        return this.httpService.postIResponse(this.BASE_URL + ClientUrlBasic.GET_PROFILE);
    }

    editProfile(clientModel: FmClientModel) {
        return this.httpService.postIResponseWithBody(this.BASE_URL + ClientUrlBasic.EDIT_PROFILE, clientModel);
    }

    changePassword(verifyUserRequestModel: VerifyUserRequestModel): Observable<IResponse<boolean>> {
        return this.httpService.postIResponseWithBody(this.BASE_URL + ClientUrlBasic.CHANGE_PASSWORD, verifyUserRequestModel);
    }

    getPasswordLastUpdatedDate() {
        return this.httpService.postIResponse(this.BASE_URL + ClientUrlBasic.GET_PASSWORD_UPDATED_DATE);
    }
}
