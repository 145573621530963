import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from '@modules/auth/page/login/login.component';
import {NzInputModule} from 'ng-zorro-antd/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {SharedCommonModule} from '../shared-lib/common/common.module';
import { TwoFaRegistrationComponent } from '@modules/auth/component/two-fa-registration/two-fa-registration.component';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NgOtpInputModule} from 'ng-otp-input';
import { ForgotUsernameModalComponent } from './component/forgot-username-modal/forgot-username-modal.component';
import { ForgotPasswordModalComponent } from './component/forgot-password-modal/forgot-password-modal.component';
import { ForgotPasswordOtpComponent } from './component/forgot-password-otp/forgot-password-otp.component';
import {UiInputWrapperComponent} from '@modules/shared-lib/common/component-revamp/ui-input-wrapper/ui-input-wrapper.component';
import {UiNotesComponent} from '@modules/shared-lib/common/component-revamp/ui-notes/ui-notes.component';
import { ResetPasswordModalComponent } from './component/reset-password-modal/reset-password-modal.component';
import {UiInputComponent} from '@modules/shared-lib/common/component-revamp/ui-input/ui-input.component';
import {NumericDirective} from '@modules/shared-lib/common/component-revamp/ui-input/floating-label/directive/numeric.directive';

@NgModule({
  declarations: [
    LoginComponent,
    TwoFaRegistrationComponent,
    ForgotUsernameModalComponent,
    ForgotPasswordModalComponent,
    ForgotPasswordOtpComponent,
    ResetPasswordModalComponent,

  ],
    imports: [
        CommonModule,
        AuthRoutingModule,
        NzInputModule,
        ReactiveFormsModule,
        NzButtonModule,
        SharedCommonModule,
        NzFormModule,
        FormsModule,
        NgOtpInputModule,
        UiInputWrapperComponent,
        UiNotesComponent,
        UiInputComponent,
        NumericDirective,
    ]
})
export class AuthModule { }
