import {PreloadingStrategy, Route} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class IPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: Function): Observable<any> {
    const preload = route?.data?.preload;

    if (preload) {
      const conn = typeof window !== 'undefined' ? (navigator as any).connection : undefined;

      if (conn) {
        // don't preload if client is at a slow connection.
        if (conn.effectiveType !== '4g' || conn.saveData) {
          return of(null);
        }
      }

      return load();
    } else {
      return of(null);
    }
  }
}
